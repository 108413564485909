<template>
  <PortalModal
    :title="$t('modal.showTransaction.title')"
    size="large"
  >
    <template #body-and-footer>
      <div class="tw-flex tw-flex-col tw-p-8 tw-mb-4 tw-items-center tw-rounded-lg tw-bg-gray-10">
        <div class="tw-text-xl tw-font-bold tw-mb-1">
          <CurrencyFormat
            :currency="transaction.attributes.amount.currency"
            :value="
              transaction.attributes.payment_method === 'cash'
                ? transaction.attributes.amount.value / 100
                : transaction.attributes.amount.value
            "
          />
        </div>
        <div class="tw-text-md">
          {{ formatDatetime(transaction.attributes.transaction_date) }}
        </div>
      </div>

      <KeyValueList
        v-if="transaction.attributes"
        :items="listItems"
      >
        <template #cardScheme>
          <CardFormat
            :card="transaction.attributes.card_scheme"
            placeholder=""
            :digits="formatDigits(transaction.attributes.pan_start, transaction.attributes.pan_end)"
          />
        </template>
        <template #settlement>
          <UILink
            v-if="
              transaction.attributes?.settlement?.paid_out_timestamp &&
              //Do not show the link, if we're already on the page we wish to link to
              $route.name !== RouteNames.FINANCES_PAYOUT_DETAILS_TRANSACTIONS
            "
            :to="{
              name: RouteNames.FINANCES_PAYOUT_DETAILS_TRANSACTIONS,
              params: {
                year: getMonthOrYearOfDate('year', transaction.attributes.settlement.paid_out_timestamp),
                month: getMonthOrYearOfDate('month', transaction.attributes.settlement.paid_out_timestamp),
                payoutId: transaction.attributes.settlement.id,
              },
              query: {
                back: route.fullPath,
              },
            }"
          >
            {{ transaction.attributes.settlement.id }}
          </UILink>

          <template v-else>
            {{ transaction.attributes.settlement?.id }}
          </template>
        </template>

        <template #transactionId>
          <div class="tw-inline-flex tw-justify-between tw-w-full">
            {{ transaction.attributes.reference }}
            <LuiButton
              v-tooltip="$t('tooltip.copyToClipboard')"
              size="small"
              emphasis="low"
              icon="copy"
              @click="copyToClipboard(transaction.attributes.reference)"
            />
          </div>
        </template>

        <template #store>
          <UILink
            :to="{
              name: RouteNames.ADMINISTRATION_STORE_EDIT,
              params: { storeId: transaction.attributes.store_id },
            }"
          >
            {{ transaction.attributes.store_name }}
          </UILink>
        </template>

        <template #terminal>
          <UILink
            :to="{
              name: RouteNames.ADMINISTRATION_TERMINAL_EDIT,
              params: { terminalId: transaction.attributes.terminal_id },
            }"
          >
            {{ transaction.attributes.terminal_name }}
          </UILink>
        </template>
      </KeyValueList>
    </template>
  </PortalModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { KeyValueListItem } from '@/interfaces/Frontend/KeyValueList'
import type { PortalTransaction } from '@/interfaces/PortalTransaction'

import KeyValueList from '@/components/Lists/KeyValueList.vue'
import PortalModal from '@/components/Modals/Templates/PortalModal.vue'
import UILink from '@/components/UI/UILink.vue'
import CardFormat from '@/components/Utilities/CardFormat.vue'
import CurrencyFormat from '@/components/Utilities/CurrencyFormat.vue'
import { formatCurrency, formatDatetime } from '@/formatters'
import notify from '@/notify'
import { getMonthOrYearOfDate } from '@/shared/datetimeService'
import { RouteNames } from '@/shared/routes'
import { useMerchantsStore } from '@/stores/merchants'
import { localisedCategoryForTransaction, localisedStatusForTransaction } from '@/utils/string/cardTransactions'
import { formatCardNumberFromPan } from '@/utils/string/creditcard'

const props = defineProps<{
  transaction: PortalTransaction
}>()

const { t } = useI18n()
const route = useRoute()

const { selectedMerchantIsFromCountry } = useMerchantsStore()

const formatDigits = (panStart: string, panEnd: string) => {
  return formatCardNumberFromPan(panStart, panEnd)
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  notify.success(t('notification.copiedToClipboard'))
}

const listItems = computed<KeyValueListItem[]>(() => {
  let items: KeyValueListItem[] = []
  if (props.transaction.attributes.card_scheme) {
    items.push({
      label: t('transactions.card'),
      slot: 'cardScheme',
    })
  }
  items.push({
    label: t('transactions.transactionID'),
    slot: 'transactionId',
  })
  if (props.transaction.attributes.transaction_fee) {
    items.push({
      label: t('transaction.transactionFee'),
      value: formatCurrency(
        props.transaction.attributes.transaction_fee.value,
        props.transaction.attributes.transaction_fee.currency,
      ),
    })
  }
  if (
    props.transaction.attributes.transaction_fee_vat &&
    (selectedMerchantIsFromCountry('DK') || selectedMerchantIsFromCountry('SE'))
  ) {
    items.push({
      label: t('transaction.transactionFeeVat'),
      value: formatCurrency(
        props.transaction.attributes.transaction_fee_vat.value,
        props.transaction.attributes.transaction_fee_vat.currency,
      ),
    })
  }
  if (props.transaction.attributes.settlement?.id) {
    items.push({
      label: t('transaction.payoutReference'),
      slot: 'settlement',
    })
  }
  if (props.transaction.attributes.settlement?.paid_out_timestamp) {
    items.push({
      label: t('transaction.payoutDate'),
      value: formatDatetime(props.transaction.attributes.settlement.paid_out_timestamp),
    })
  }
  items.push({
    label: t('transactions.category'),
    value: localisedCategoryForTransaction(props.transaction),
  })
  if (props.transaction.attributes.status !== 'none') {
    items.push({
      label: t('transactions.status.label'),
      value: localisedStatusForTransaction(props.transaction),
    })
  }

  if (props.transaction.attributes.store_name && props.transaction.attributes.store_id) {
    items.push({
      label: t('transactions.location'),
      slot: 'store',
    })
  }

  if (props.transaction.attributes.terminal_name && props.transaction.attributes.terminal_id) {
    items.push({
      label: t('transactions.terminal'),
      slot: 'terminal',
    })
  }
  return items
})
</script>

<style lang="scss" scoped>
.table tr:last-child {
  th,
  td {
    border-bottom: none;
  }
}
</style>
