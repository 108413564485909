<template>
  <div>
    <div class="tw-my-4 lg:tw-w-4/12">
      <TextBlockFormGroup
        v-model:value="searchedLocation"
        prefix-icon="search"
        :placeholder="$t('payoutDetails.locations.search')"
        @update:value="filterLocation"
      />
    </div>
    <LoadingSpinner v-if="loadingItems" />

    <BasicTable
      v-else
      :columns="columns"
      :items="sortedItems"
      :empty-message="$t('payoutDetails.locations.noLocationsToDisplay')"
      :sort="tableSort"
      @table-heading-clicked="setOrder"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { BasicTableColumn, ListSorting } from '@/interfaces/Frontend/BasicTable'
import type { PortalPayoutSummaryLocation } from '@/interfaces/PortalPayoutSummary'

import TextBlockFormGroup from '@/components/FormGroups/Block/TextBlockFormGroup.vue'
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'
import BasicTable from '@/components/Lists/BasicTable.vue'
import { formatCurrency, formatDate } from '@/formatters'
import http from '@/http'
import { handleError } from '@/shared/errorService'
import { useMerchantsStore } from '@/stores/merchants'
import { useTransactionsStore } from '@/stores/transactions-store'
import { formatFiltersForApi } from '@/utils/transactionUtils'

const { t } = useI18n()
const route = useRoute()

const locations = ref<PortalPayoutSummaryLocation[]>([])

const hasVatTransactionFees = computed(() => selectedMerchantIsFromCountry('DK') || selectedMerchantIsFromCountry('SE'))

const columns = computed(() => {
  const columns = [
    {
      text: t('global.location'),
      class: 'tw-w-4/12',
      value: 'location',
      sort: 'location',
    },
    {
      text: t('payoutDetails.locations.salesDay'),
      class: 'tw-w-4/12',
      value: (item: PortalPayoutSummaryLocation) => formatDate(item.sales_day),
      sort: 'sales_day',
    },
    {
      text: t('payoutDetails.locations.cardTurnover'),
      class: 'tw-w-4/12',
      value: (item: PortalPayoutSummaryLocation) =>
        formatCurrency(item.card_turnover, selectedMerchant.value?.attributes.currency as string),
      sort: 'card_turnover',
    },
    {
      text: t('payoutDetails.locations.transactionFees'),
      class: 'tw-w-4/12',
      value: (item: PortalPayoutSummaryLocation) =>
        formatCurrency(item.transaction_fees, selectedMerchant.value?.attributes.currency as string),
      sort: 'transaction_fees',
    },
  ]
  if (hasVatTransactionFees.value) {
    columns.push({
      text: t('payoutDetails.locations.transactionFeesVat'),
      class: 'tw-w-4/12',
      value: (item: PortalPayoutSummaryLocation) =>
        formatCurrency(item.transaction_fees_vat, selectedMerchant.value?.attributes.currency as string),
      sort: 'transaction_fees',
    })
  }
  columns.push({
    text: t('payoutDetails.locations.payout'),
    class: 'tw-w-4/12',
    value: (item: PortalPayoutSummaryLocation) =>
      formatCurrency(item.payout, selectedMerchant.value?.attributes.currency as string),
    sort: 'payout',
  })

  return columns
})

const { selectedMerchant, selectedMerchantIsFromCountry } = {
  ...useMerchantsStore(),
  ...storeToRefs(useMerchantsStore()),
}
const displayedItems = ref<PortalPayoutSummaryLocation[]>([])
const searchedLocation = ref('')
const orderField = ref()
const orderDirection = ref(1)

const filterLocation = debounce(() => {
  displayedItems.value = JSON.parse(JSON.stringify(locations.value)).filter((item: any) =>
    item.location.toLowerCase().includes(searchedLocation.value.toLowerCase()),
  )
}, 300)

const setOrder = (column: BasicTableColumn) => {
  if (orderField.value === column.sort) {
    orderDirection.value = orderDirection.value * -1
  } else {
    orderDirection.value = 1
  }

  orderField.value = column.sort as string
}

const sortedItems = computed(() => {
  const sRows = displayedItems.value?.length ? [...displayedItems.value].concat([]) : []

  if (displayedItems.value?.length) {
    sRows?.sort((a: PortalPayoutSummaryLocation, b: PortalPayoutSummaryLocation) =>
      a[orderField.value as keyof PortalPayoutSummaryLocation] <
      b[orderField.value as keyof PortalPayoutSummaryLocation]
        ? orderDirection.value
        : -orderDirection.value,
    )
  }

  return sRows
})

const loadingItems = ref(false)

const tableSort = computed(() => {
  if (locations.value?.length && !orderField.value) {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    orderField.value = 'sales_day'
  }

  return { column: orderField.value, direction: orderDirection.value > 0 ? 'asc' : 'desc' } as ListSorting
})

const { filtersFormattedForApi } = storeToRefs(useTransactionsStore())
const filterValues = ref({})

onMounted(async () => {
  loadingItems.value = true
  filterValues.value = {
    'back': route.query.back,
    'filter[payout_id]': route.params.payoutId,
  }

  route.query

  filtersFormattedForApi.value = formatFiltersForApi(filterValues.value, selectedMerchant.value)

  try {
    const response = await http.get(
      `/api/v2/merchants/${selectedMerchant.value?.id}/transactions-by-location/${selectedMerchant.value?.attributes.license}`,
      {
        ...filtersFormattedForApi.value,
      },
    )
    locations.value = response?.data?.data || []
    displayedItems.value = locations.value
  } catch (error) {
    handleError(error)
  } finally {
    loadingItems.value = false
  }
})
</script>
