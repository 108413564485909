<template>
  <div>
    <BasicTableWithPaginationQueryFiltersAndExternalLoading
      v-if="selectedMerchant"
      ref="tableRef"
      v-model:filter-values="filterValues"
      :endpoint="`/api/v2/merchants/${selectedMerchantId}/transactions/${selectedMerchant.attributes.license}`"
      :columns="columns"
      :filters="computedFilters"
      :transform-filters-to-api-query="handleFormatFiltersForApi"
      row-is-clickable
      :merchant-id="selectedMerchantId"
      @row-clicked="selectedTransaction = $event.item"
    >
      <template #card="{ item: transaction }">
        <CardFormat
          v-if="transaction.attributes.pan_end"
          :digits="transaction.attributes.pan_end"
          :card="transaction.attributes.card_scheme"
        />
      </template>
      <template #status="{ item: transaction }">
        <div
          class="tw-flex tw-items-center tw-justify-end tw-ml-1"
          :class="classForTransaction(transaction)"
        >
          <LuiIcon
            v-if="iconForTransaction(transaction)"
            :name="iconForTransaction(transaction) as LuiIconName"
            class="tw-mr-1 tw-inline-block tw-shrink-0"
          />
          <span class="tw-inline-block">
            {{ categoryStatusForTransaction(transaction) }}
          </span>
        </div>
      </template>
    </BasicTableWithPaginationQueryFiltersAndExternalLoading>

    <ShowTransactionModal
      v-if="selectedTransaction"
      :transaction="selectedTransaction"
      @close="selectedTransaction = null"
    />
  </div>
</template>

<script setup lang="ts">
import type { LuiIconName, LuiSelectOption } from '@loomispay/loomis-ui'
import { storeToRefs } from 'pinia'
import { type Ref, computed, ref, watch } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { BasicTableColumn } from '@/interfaces/Frontend/BasicTable'
import type { PortalFilterRow, PortalFilters } from '@/interfaces/PortalFilters'
import type { PortalTransaction, PortalTransactionCategory } from '@/interfaces/PortalTransaction'

import BasicTableWithPaginationQueryFiltersAndExternalLoading from '@/components/Lists/BasicTableWithPaginationQueryFiltersAndExternalLoading.vue'
import ShowTransactionModal from '@/components/Modals/Orders/Transactions/ShowTransactionModal.vue'
import CardFormat from '@/components/Utilities/CardFormat.vue'
import { formatCurrency, formatDatetime } from '@/formatters'
import { CardType, getVerboseNameFromCardType, payfacCardTypes } from '@/shared/constants/cards'
import { useMerchantsStore } from '@/stores/merchants'
import { useTransactionsStore } from '@/stores/transactions-store'
import { formatFiltersForApi } from '@/utils/transactionUtils'
import { categoryStatusForTransaction, classForTransaction, iconForTransaction } from '@/utils/transactionUtils'

const route = useRoute()

const props = defineProps<{
  filters?: PortalFilters
  title?: string
}>()

const { t } = useI18n()

const { selectedMerchant, selectedMerchantId, selectedMerchantIsFromCountry } = {
  ...useMerchantsStore(),
  ...storeToRefs(useMerchantsStore()),
}

const { filtersFormattedForApi } = storeToRefs(useTransactionsStore())

const filterValues = ref<Record<string, string>>({})

const tableRef = ref<null | ComponentExposed<typeof BasicTableWithPaginationQueryFiltersAndExternalLoading>>(null)
const selectedTransaction = ref<null | PortalTransaction>(null)

const columns: Ref<BasicTableColumn<PortalTransaction>[]> = computed(() => {
  const columns: BasicTableColumn<PortalTransaction>[] = [
    {
      class: 'tw-w-2/3 lg:tw-w-2/12',
      text: t('transactions.date'),
      lgValue: ({ attributes }) => formatDatetime(attributes.transaction_date),
      value: ({ attributes }) => `•••• ${attributes.pan_end}`,
      subValue: ({ attributes }) =>
        `${formatDatetime(attributes.transaction_date)} ${attributes.store_name ? ` • ${attributes.store_name}` : ''}`,
      lgSubValue: null,
    },
    {
      class: 'tw-hidden lg:tw-table-cell tw-w-2/12',
      text: t('transactions.location'),
      value: 'attributes.store_name',
    },
    {
      class: 'tw-hidden lg:tw-table-cell tw-w-2/12',
      text: t('transactions.terminal'),
      value: ({ attributes }) => attributes?.terminal_name || t('transactions.noTerminal'),
    },
    {
      class: 'tw-hidden lg:tw-table-cell tw-w-2/12',
      slot: 'card',
      text: t('transactions.card'),
    },
    {
      class: 'tw-hidden lg:tw-table-cell tw-w-2/12',
      slot: 'status',
      text: null,
    },
  ]

  if (selectedMerchantIsFromCountry('DK') || selectedMerchantIsFromCountry('SE')) {
    columns.push({
      class: 'tw-hidden lg:tw-table-cell tw-text-right tw-w-1/3 lg:tw-w-3/12',
      text: t('transactions.transactionFeeVat'),
      value: ({ attributes }) =>
        attributes.transaction_fee_vat
          ? formatCurrency(attributes.transaction_fee_vat.value, attributes.transaction_fee_vat.currency)
          : '',
    })
  }

  columns.push({
    class: 'tw-hidden lg:tw-table-cell tw-text-right tw-w-1/3 lg:tw-w-2/12',
    text: t('transactions.transactionFee'),
    value: ({ attributes }) =>
      attributes.transaction_fee
        ? formatCurrency(attributes.transaction_fee.value, attributes.transaction_fee.currency)
        : '',
  })

  columns.push({
    class: 'tw-text-right tw-w-1/3 lg:tw-w-2/12',
    innerClass: 'max-lg:tw-pr-0',
    text: t('transactions.amount'),
    value: ({ attributes }) => formatCurrency(attributes.amount.value, attributes.amount.currency),
    subValue: categoryStatusForTransaction,
    lgSubValue: null,
  })

  columns.push({
    class: 'tw-w-4 tw-pl-0 lg:tw-hidden',
    type: 'clickIndicator',
  })

  return columns
})

const viableCardSchemes = computed<CardType[]>(() => {
  if (selectedMerchant.value?.attributes?.license === 'PAYFAC') {
    return payfacCardTypes
  }
  return Object.values(CardType)
})

const computedFilters = computed<PortalFilters>(() => {
  if (props.filters) {
    return props.filters
  }

  const categoryOptions: LuiSelectOption<PortalTransactionCategory>[] = [
    {
      value: 'purchase',
      label: t('transactions.purchase'),
    },
    {
      value: 'refund',
      label: t('transactions.refund'),
    },
    {
      value: 'reversal',
      label: t('transactions.reversal'),
    },
    {
      value: 'chargeback',
      label: t('transactions.chargeback'),
    },
    {
      value: 'balance-reset',
      label: t('transactions.balance-reset'),
    },
  ]

  return [
    [
      {
        widget: 'text',
        width: 3,
        name: 'search',
      },
      {
        widget: 'date',
        width: 3,
        name: 'transaction_date_from',
        label: t('placeholder.searchFromDate'),
      },
      {
        widget: 'date',
        width: 3,
        name: 'transaction_date_to',
        label: t('placeholder.searchToDate'),
      },
      {
        widget: 'text',
        width: 3,
        name: 'amount',
        placeholder: t('placeholder.searchPrice'),
      },
    ],
    [
      {
        widget: 'adaptive',
        width: 3,
        name: 'store_id',
        endpoint: '/api/v2/stores',
        params: { 'filter[merchant_id]': selectedMerchantId.value },
        nullable: true,
        placeholder: t('filters.allLocations'),
      },
      {
        widget: 'adaptive',
        width: 3,
        name: 'terminal_id',
        endpoint: '/api/v2/terminals',
        params: { 'filter[merchant_id]': selectedMerchantId.value },
        nullable: true,
        placeholder: t('filters.allTerminals'),
      },
      // Only show the currency filter for ISO merchants
      selectedMerchant.value!.attributes?.license === 'ISO'
        ? {
            widget: 'text',
            width: 3,
            name: 'currency',
            placeholder: t('placeholder.searchCurrency'),
          }
        : false,
      {
        widget: 'select',
        width: 3,
        name: 'card_scheme',
        placeholder: t('placeholder.allCardTypes'),
        nullable: true,
        options: viableCardSchemes.value.map((cardType) => ({
          value: cardType,
          label: getVerboseNameFromCardType(cardType),
        })),
      },
      {
        widget: 'select',
        width: 3,
        name: 'category',
        placeholder: t('transactions.allCategories'),
        nullable: true,
        options: categoryOptions,
      },
    ].filter(Boolean) as PortalFilterRow, // Remove the falsy values
    [
      {
        widget: 'button',
        event: 'clear',
        text: t('global.clear'),
        props: { emphasis: 'secondary' },
      },
      {
        widget: 'submit',
      },
      {
        width: 4,
        widget: 'checkbox',
        name: 'include_declined',
        label: t('transactions.includeDeclined'),
      },
      {
        widget: 'blank',
        width: 6,
      },
    ],
  ]
})

const handleFormatFiltersForApi = (query: any) => {
  const routeParams = route.params
  const _query = {
    ...query,
    merchant_id: selectedMerchantId.value,
    payout_id: routeParams.payoutId,
    acquirer: routeParams.acquirer,
  }
  filtersFormattedForApi.value = formatFiltersForApi(_query, selectedMerchant.value)
  return filtersFormattedForApi.value
}

watch(
  () => selectedMerchant,
  (newValue: any) => {
    if (selectedMerchant.value) {
      filtersFormattedForApi.value = formatFiltersForApi(filterValues.value, newValue.value)
    }
  },
)
</script>
